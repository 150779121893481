<template>
  <div>
    <v-layout v-if="aboutData.middle" wrap justify-center>
      <v-flex xs12 sm12 lg11 xl8 align-self-center>
        <v-card
          :min-height="$vuetify.breakpoint.name == 'xl' ? '50vh' : '30vh'"
          flat
          light
          color="#FFF"
        >
          <v-layout py-6 py-sm-10 py-md-12 style="min-height:30vh" wrap justify-center fill-height>
            <v-flex
              xs12
              sm6
              md6
              offset-lg-1
              lg5
              pl-3
              pr-3
              pl-sm-10
              pr-sm-6
              pl-md-5
              pr-md-8
              align-self-center
            >
              <v-layout wrap>
                <v-flex xs12 md7>
                  <span
                    style="
                      font-family: Bahnschirft;
                      font-size: 50px !important;
                      font-weight: 800;
                      text-align:justify;
                    "
                  >
                    {{ aboutData.middle.leftText }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12 sm6 md6 lg5 align-self-center pl-md-8 pl-3 pr-3>
              <v-layout wrap>
                <v-flex xs12 md10 px-1>
                  <span
                    v-html="aboutData.middle.rightText"
                    style="
                      font-family: Bahnschirft;
                      font-size: 18px !important;
                      letter-spacing: 0.24px;
                      text-align:justify;
                    "
                  >
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["aboutData"],
};
</script>

<style  scoped>
.conGrad {
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);
}
.graddy {
  background: transparent
    linear-gradient(
      100deg,
      #f5a624 0%,
      #f9b52c 44%,
      #fbba2f 59%,
      #fdc033 78%,
      #fdc033 78%,
      #ffc837 100%
    );
}
</style>