<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md6 lg6 xl8 align-self-center>
        <v-card
        tile
          :min-height="
            $vuetify.breakpoint.name == 'xs'
              ? '50vh'
              : $vuetify.breakpoint.name == 'sm'
              ? '50vh'
              : $vuetify.breakpoint.name == 'md'
              ? '60vh'
              : $vuetify.breakpoint.name == 'lg'
              ? '40vh'
              : '50vh'
          "
          flat
          :color="aboutData.missionColor"
        >
          <v-layout
            py-6
            py-sm-5
            fill-height
            wrap
            justify-center
            :style="{
              'min-height':
                $vuetify.breakpoint.name == 'xs'
                  ? '50vh'
                  : $vuetify.breakpoint.name == 'sm'
                  ? '50vh'
                  : $vuetify.breakpoint.name == 'md'
                  ? '60vh'
                  : $vuetify.breakpoint.name == 'lg'
                  ? '40vh'
                  : '50vh',
            }"
          >
            <v-flex xs12 sm6 md6 lg5 align-self-center pl-md-8 pl-3 pr-3>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span
                    style="
                      font-family: Bahnschirft;
                      font-size: 12px !important;
                      color: #fff;
                    "
                  >
                    {{ aboutData.missionTitle }}
                  </span>
                </v-flex>
                <v-flex xs12 md12 pt-3>
                  <span
                    style="
                      font-family: Bahnschirft;
                      font-size: 22px !important;
                      letter-spacing: 0.35px;
                      color: #fff;
                    "
                  >
                    {{ aboutData.missionText }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6 xl8 align-self-center>
        <v-card
        tile
          :min-height="
            $vuetify.breakpoint.name == 'xs'
              ? '50vh'
              : $vuetify.breakpoint.name == 'sm'
              ? '50vh'
              : $vuetify.breakpoint.name == 'md'
              ? '60vh'
              : $vuetify.breakpoint.name == 'lg'
              ? '40vh'
              : '50vh'
          "
          flat
          :color="aboutData.visionColor"
        >
          <v-layout
            py-6
            py-sm-5
            wrap
            justify-center
            :style="{
              'min-height':
                $vuetify.breakpoint.name == 'xs'
                  ? '50vh'
                  : $vuetify.breakpoint.name == 'sm'
                  ? '50vh'
                  : $vuetify.breakpoint.name == 'md'
                  ? '60vh'
                  : $vuetify.breakpoint.name == 'lg'
                  ? '40vh'
                  : '50vh',
            }"
            fill-height
          >
            <v-flex xs12 sm6 md6 lg5 align-self-center pl-md-8 pl-3 pr-3>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span
                    style="
                      font-family: Bahnschirft;
                      font-size: 12px !important;
                      color: #fff;
                    "
                  >
                    {{ aboutData.visionTitle }}
                  </span>
                </v-flex>
                <v-flex xs12 md12 pt-3>
                  <span
                    style="
                      font-family: Bahnschirft;
                      font-size: 22px !important;
                      letter-spacing: 0.35px;
                      color: #fff;
                    "
                  >
                    {{ aboutData.visionText }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["aboutData"],
  data() {
    return {
      values: [
        {
          title: "OUR VISION",
          value:
            " To be at the forefront of our field and be a place where innovation flourishes.",
          color: "#2954DF",
        },
        {
          title: "MISSION",
          value:
            "To provide cutting edge Technology with high quality to users, thereby making lives simpler.",
          color: "#53B57A",
        },
      ],
    };
  },
};
</script>

<style  scoped>
.conGrad {
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);
}
.graddy {
  background: transparent
    linear-gradient(
      100deg,
      #f5a624 0%,
      #f9b52c 44%,
      #fbba2f 59%,
      #fdc033 78%,
      #fdc033 78%,
      #ffc837 100%
    );
}
</style>