var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","lg6":"","xl8":"","align-self-center":""}},[_c('v-card',{attrs:{"tile":"","min-height":_vm.$vuetify.breakpoint.name == 'xs'
            ? '50vh'
            : _vm.$vuetify.breakpoint.name == 'sm'
            ? '50vh'
            : _vm.$vuetify.breakpoint.name == 'md'
            ? '60vh'
            : _vm.$vuetify.breakpoint.name == 'lg'
            ? '40vh'
            : '50vh',"flat":"","color":_vm.aboutData.missionColor}},[_c('v-layout',{style:({
            'min-height':
              _vm.$vuetify.breakpoint.name == 'xs'
                ? '50vh'
                : _vm.$vuetify.breakpoint.name == 'sm'
                ? '50vh'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '60vh'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '40vh'
                : '50vh',
          }),attrs:{"py-6":"","py-sm-5":"","fill-height":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","lg5":"","align-self-center":"","pl-md-8":"","pl-3":"","pr-3":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"Bahnschirft","font-size":"12px !important","color":"#fff"}},[_vm._v(" "+_vm._s(_vm.aboutData.missionTitle)+" ")])]),_c('v-flex',{attrs:{"xs12":"","md12":"","pt-3":""}},[_c('span',{staticStyle:{"font-family":"Bahnschirft","font-size":"22px !important","letter-spacing":"0.35px","color":"#fff"}},[_vm._v(" "+_vm._s(_vm.aboutData.missionText)+" ")])])],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","lg6":"","xl8":"","align-self-center":""}},[_c('v-card',{attrs:{"tile":"","min-height":_vm.$vuetify.breakpoint.name == 'xs'
            ? '50vh'
            : _vm.$vuetify.breakpoint.name == 'sm'
            ? '50vh'
            : _vm.$vuetify.breakpoint.name == 'md'
            ? '60vh'
            : _vm.$vuetify.breakpoint.name == 'lg'
            ? '40vh'
            : '50vh',"flat":"","color":_vm.aboutData.visionColor}},[_c('v-layout',{style:({
            'min-height':
              _vm.$vuetify.breakpoint.name == 'xs'
                ? '50vh'
                : _vm.$vuetify.breakpoint.name == 'sm'
                ? '50vh'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '60vh'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '40vh'
                : '50vh',
          }),attrs:{"py-6":"","py-sm-5":"","wrap":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","lg5":"","align-self-center":"","pl-md-8":"","pl-3":"","pr-3":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"Bahnschirft","font-size":"12px !important","color":"#fff"}},[_vm._v(" "+_vm._s(_vm.aboutData.visionTitle)+" ")])]),_c('v-flex',{attrs:{"xs12":"","md12":"","pt-3":""}},[_c('span',{staticStyle:{"font-family":"Bahnschirft","font-size":"22px !important","letter-spacing":"0.35px","color":"#fff"}},[_vm._v(" "+_vm._s(_vm.aboutData.visionText)+" ")])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }